import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import RequestForProposal, { Company } from "../components/RequestForProposal";
import { ACTIVE_PAGE } from "../components/nav/utils";

const companies: Company[] = [
  {
    name: "Core States Group",
    logoSrc:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fcorestatesgroup%2FCore%20States%20Group%20Logo.jpg?alt=media&token=dd40c6d5-2ce6-4a97-a5ed-7cb926248c6f",
  },
  {
    name: "Bernardon",
    logoSrc:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fcorestatesgroup%2FBernardon%20Logo.jpg?alt=media&token=b8a30845-d10d-4983-ae7e-a8e89060d972",
  },
  {
    name: "Core States Energy",
    logoSrc:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fcorestatesgroup%2FCore%20States%20Energy%20Logo.jpg?alt=media&token=8f0dc9e6-eba0-443a-8a85-caad20ebebca",
  },
  {
    name: "Integrated Image",
    logoSrc:
      "https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fcorestatesgroup%2FIntegrated%20Image%20Logo.jpg?alt=media&token=0f029f59-6cb6-4d19-a592-a638267a9baf",
  },
];

export default function CoreStatesRfpPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.CORESTATESGROUP}>
      <Seo title="Core States Group Request For Proposal | Robotic Imaging" />

      <header className="site__header"></header>

      <main className="content">
        <RequestForProposal companies={companies} />
      </main>
    </SiteWrapper>
  );
}
